.empty-404 {
  width: 100%;
  text-align: center;
  padding: 20px 0;

  .waraemon {
    width: 100px;
  }//.waraemon
  .message {
    font-size: 12px;
    margin-top: 15px;
  }//.message
}//.empty-404
